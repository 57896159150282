import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
import router from './router'
import store from './store'
const app=createApp(App)
app.config.globalProperties.$key = "/img";// 定义全局变量 线上模式
 // app.config.globalProperties.$key = "http://localhost:3100" // 定义全局变量 线下模式
app.use(ElementPlus,{locale}).use(store).use(router).mount('#app')
