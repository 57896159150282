<template>
	<!-- 电脑模式 -->
	<el-col :span="24" class="hidden-xs-only">
		<div class="common-layout">
			<el-container>
				<el-header style="border-bottom: solid 1px #d2d2d6;
		border-top: solid 1px #d2d2d6;height: 80px;">
					<p class="p1">联系我们:</p>
				</el-header>
				<el-main>
					<el-row>
					    <el-col :span="20"><div style="margin-top: 10px;">
						<span class="span1" v-html="lxwmlist.content"></span>
					</div></el-col>
					<!-- 微信码 -->
					    <el-col :span="4">
							<div style="width: 200px;height: 200px;margin-top: 30px;">
								<img style="width: 100%;height: 100%;" src="../../src/assets/c2c523006a3eebb961ce8a3d9601b973.jpg">
							</div>
							<p style="margin-left: 28px;color: #666;">微信扫码 关注我们</p>
							</el-col>
					  </el-row>
				</el-main>
			</el-container>
		</div>
	</el-col>

	<!-- 手机模式 -->
	<el-row class="hidden-sm-and-up">
		<el-col :span="24"></el-col>
		<div class="common-layout" style="min-height: 260px;">
			<el-container>
				<el-header>
					<p class="p1">联系我们:</p>
				</el-header>
				<el-main>
					<div style="margin-top: 10px;">
						<span class="span1" v-html="lxwmlist.content"></span>
					</div>
				</el-main>
			</el-container>
		</div>
	</el-row>
</template>

<script setup>
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const lxwmlist = ref([])
	const wxqqllist = ref([])
	onMounted(async()=>{
		var res = await axios.get("/webapi/lxwm/list")
		lxwmlist.value = res.data.data[0]
		var res1 = await axios.get("/webapi/wxqq/list")
		wxqqllist.value = res1.data.data[0];
		// console.log(res1.data.data)
	})
</script>

<style lang="scss" scoped>
	.common-layout {
		width: 1450px;
		margin: 0 auto;
	}

	.p1 {
		font-size: 30px;
		margin-left: 10px;
		line-height: 80px;
	}

	.p2 {
		font-weight: normal;
		text-align: center;
		line-height: 120px;
	}

	.h1box {
		height: 120px;
		border-bottom: solid 2px #d2d2d6;
	}

	.h1 {
		text-align: center;
		font-weight: normal;
		font-size: 30px;
		line-height: 120px;
	}

	.span1 {
		line-height: 35px;
		color: #666;

	}
</style>