<template>
	<el-menu :default-active="$route.meta.name" class="el-menu-demo hidden-xs-only" mode="horizontal" :router="true"
		 active-text-color="white"
		style="width: 1450px;margin: 0 auto;border-top: solid 1px #dcdfe6;">
		<el-menu-item :route="{path:'/'}" index="active1" style="height: 35px; margin: 15px 40px;">公司首页</el-menu-item>
		<el-menu-item :route="{path:'/gsjj'}" index="active2" style="height: 35px; margin: 15px 75px;">公司简介</el-menu-item>
		<el-menu-item :route="{path:'/product'}" index="active4" style="height: 35px; margin: 15px 75px;">服务项目</el-menu-item>
		<el-menu-item :route="{path:'/zdlf'}" index="active8" style="height: 35px; margin: 15px 75px;">维修案例</el-menu-item>
		<el-menu-item :route="{path:'/news'}" index="active3" style="height: 35px; margin: 15px 75px;">新闻资讯</el-menu-item>
		<el-menu-item index="/lxwm" style="height: 35px; margin: 15px 75px;">联系我们</el-menu-item>
	</el-menu>

	<div class="hidden-sm-and-up ponenavbar">
		<!-- 手机模式 -->
		<el-row>
			<el-col :span="8" class="col1" @click="$router.push(`/`)">
				<p>公司首页</p>
			</el-col>
			<el-col :span="8" class="col1" @click="$router.push(`/gsjj`)">
				公司简介
			</el-col>
			<el-col :span="8" class="col1" @click="$router.push(`/product`)">
				服务项目
			</el-col>
			<el-col :span="8" class="col1" @click="$router.push(`/zdlf`)">
				维修案例
			</el-col>
			<el-col :span="8" class="col1" @click="$router.push(`/news`)">
				新闻资讯
			</el-col>
			<el-col :span="8" class="col1" @click="$router.push(`/lxwm`)">
				联系我们
			</el-col>
		</el-row>
	</div>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {useRoute} from 'vue-router'
	const route = useRoute()
</script>

<style lang="scss" scoped>
	.el-menu-demo {
		height: 64px !important;;
	}

	.el-menu-item {
		font-size: 15px !important;;
		font-weight: bold !important;;
		height: 32px !important;;
		transition: none !important;
	}
// 控制导航条移入椭圆形
	 .el-menu-item:hover {
		border-radius: 70px !important;;
	}
// 
	.el-menu-item.is-active {
		background: #1ea4fe !important;
		border-radius: 70px !important;
	}
    .el-menu-item.is-active:hover {
	 
    }
	.ponenavbar {
		width: 100%;
		// height: 105px;
		background-color: #1ea4fe;
	}

	.span1 {
		// margin: 0px 10px;
		// line-height: 20px;
	}

	.col1 {
		// margin-top: 12px;
		text-align: center;
		color: white;
		padding: 6px;
		background-color: #1ea4fe;
		border: 1px solid whitesmoke;
	}
</style>