import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Gsjj from '../views/Gsjj.vue'
import News from '../views/News.vue'
import Newslist from '../views/viewslist/Newslist.vue'
import Product from '../views/Product.vue'
import Productlist from '../views/viewslist/Productlist.vue'
import Zdlf from '../views/Zdlf.vue'
import Smwx from '../views/Smwx.vue'
import Bswx from '../views/Bswx.vue'
import Bgwx from '../views/Bgwx.vue'
import Dbwx from '../views/Dbwx.vue'
import Zdlflist from '../views/viewslist/Zdlflist.vue'
import Bgwxlist from '../views/viewslist/Bgwxlist.vue'
import Smwxlist from '../views/viewslist/Smwxlist.vue'
import Bswxlist from '../views/viewslist/Bswxlist.vue'
import Dbwxlist from '../views/viewslist/Dbwxlist.vue'
import Zdlflistlbt from '../views/viewslist/Zdlflistlbt.vue'
import Lxwm from '../views/Lxwm.vue'


// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [
	{
		path:'/',
		name:'home',
		component:Home,
		meta:{
			name:'active1',
		}
	},
	{
		path:'/gsjj',
		name:'gsjj',
		component:Gsjj,
		meta:{
			name:'active2'
		}
	},
	{
		path:'/news',
		name:'news',
		component:News,
		meta:{
			name:'active3'
		}
	},
	{
		path:'/product',
		name:'product',
		component:Product,
		meta:{
			name:'active4'
		}
	},
	{
		path:'/zdlf',
		name:'zdlf',
		component:Zdlf,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/smwx',
		name:'smwx',
		component:Smwx,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/bgwx',
		name:'bgwx',
		component:Bgwx,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/bswx',
		name:'bswx',
		component:Bswx,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/dbwx',
		name:'dbwx',
		component:Dbwx,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/lxwm',
		name:'lxwm',
		component:Lxwm
	},
	{
		path:'/newslist/:id',
		name:'newslist',
		component:Newslist,
		meta:{
			name:'active3'
		}
	},
	{
		path:'/productlist',
		name:'productlist',
		component:Productlist,
		meta:{
			name:'active4'
		}
	},
	{
		path:'/zdlflist',
		name:'zdlflist',
		component:Zdlflist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/smwxlist',
		name:'smwxlist',
		component:Smwxlist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/bgwxlist',
		name:'bgwxlist',
		component:Bgwxlist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/bswxlist',
		name:'bswxlist',
		component:Bswxlist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/dbwxlist',
		name:'dbwxlist',
		component:Dbwxlist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/zdlflistlbt',
		name:'zdlflistlbt',
		component:Zdlflistlbt,
		meta:{
			name:'active8'
		}
	},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

 // 路由跳转前的钩子，进度条加载
router.beforeEach((to,from,next)=>{
	NProgress.start();
	next()
})
// 路由跳转后的钩子，进度条关闭
router.afterEach((to,from,next)=>{
	NProgress.done();
}) 
export default router
