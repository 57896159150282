<template>
	<div class="youqilianjie hidden-xs-only" v-if="data.show">
		<span class="yqlj">友情链接:</span>
		<a v-for="itme in yqllist" :key="_id" class="yqlj1" v-bind:href="itme.yqlink">{{itme.yqname}}</a>
	</div>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref,reactive} from "vue";
	import axios from 'axios';
	const yqllist = ref([])
	const data = reactive({
		show: true, //显示隐藏
	})
	onMounted(async()=>{
		// 首页轮播图
		var res1 = await axios.get("/webapi/yq/list")
		yqllist.value = res1.data.data
		const sss = yqllist.value.length
		if (sss==0) {
			data.show=false
		}
	})
</script>

<style lang="scss" scoped>
	/* 友情链接 */
	  .youqilianjie {
	  	width: 1400px;
	  	height: 60px;
	  	margin: 0px auto;
	  	line-height: 60px;
	  	// border-top: solid 2px #d2d2d6;
		margin: 0px auto;
	  }
	
	  .youqilianjie a {
	  	margin-right: 20px;
	  }
</style>