<template>
	<!-- 电脑模式 -->
	<el-col :span="24" class="hidden-xs-only" style="min-height: 200px;">
		<div class="common-layout">
			<el-container>
				<el-header>
					<p class="p1">{{newsdata.newsname}}</p>
				</el-header>
				<el-main>
					<div style="margin-top: 20px;">
						<span class="span1" v-html="newsdata.content"></span>
					</div>
				</el-main>
			</el-container>
		</div>
	</el-col>

	<!-- 手机模式 -->
	<el-row class="hidden-sm-and-up">
		<el-col :span="24"></el-col>
		<div class="common-layout">
			<el-container>
				<el-header>
					<p class="p1">{{newsdata.newsname}}</p>
				</el-header>
				<el-main>
					<div style="margin-top: 20px;">
						<span class="span1" v-html="newsdata.content"></span>
					</div>
				</el-main>
			</el-container>
		</div>
	</el-row>
</template>

<script setup>
	import {onMounted,ref} from "vue";
	import {useRoute} from 'vue-router';
	import axios from 'axios';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const newsdata = ref([])
	const route = useRoute()
	onMounted(async()=>{
		const res1 = await axios.get(`/webapi/news/list/${route.params.id}`)
		newsdata.value = res1.data.data[0]
	})
</script>

<style lang="scss" scoped>
	.common-layout {
		width: 1450px;
		margin: 0 auto;
	}

	.p1 {
		font-size: 30px;
		margin-left: 10px;
		line-height: 80px;
		border-bottom: solid 2px #d2d2d6;
	}

	.p2 {
		font-weight: normal;
		text-align: center;
		line-height: 120px;
	}

	.h1box {
		height: 120px;
		border-bottom: solid 2px #d2d2d6;
	}

	.h1 {
		text-align: center;
		font-weight: normal;
		font-size: 30px;
		line-height: 120px;
	}

	.span1 {
		line-height: 35px;
		color: #666;

	}
</style>