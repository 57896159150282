<template>
	<el-affix position="bottom" :offset="1">
		<el-row style="background-color: black;">
			<div class="footer hidden-sm-and-up" style="position: relative;">
				<div style="margin-left: 12%;margin-top: 10px;">
					<el-row>
						<el-col :span="6"><el-icon color="white" size="28" @click="$router.push('/')">
								<HomeFilled />
							</el-icon>
							<p style="position: absolute;color: #fff;font-size: 14px;">首页</p>
						</el-col>
						<el-col :span="6"><el-icon color="white" size="28" @click="callPhone">
								<Iphone />
							</el-icon>
							<p style="position: absolute;color: #fff;font-size: 14px;">电话</p>
						</el-col>
						<el-col :span="6"><el-icon color="white" size="28" @click="dialogTableVisible = true">
								<ChatDotRound />
							</el-icon>
							<p style="position: absolute;color: #fff;font-size: 14px;">微信</p>
						</el-col>
						<el-col :span="6"><el-icon color="white" size="28" @click="$router.push('/lxwm')">
								<Location />
							</el-icon>
							<p style="position: absolute;color: #fff;font-size: 14px;">联系</p>
						</el-col>
					</el-row>
				</div>

			</div>
		</el-row>
	</el-affix>
	<el-dialog v-model="dialogTableVisible" width="70%" style="hidden-sm-and-up">
	   <div><img style="width: 100%;height: 100%;" :src="proxy.$key + wxqqllist[0].avatar"></div>
		<p style="text-align: center;font-size: 18px;height: 20px;margin-top: 10px;">微信号:<el-button @click="aaa" style="margin-left: 10px;" type="primary">{{wxqqllist[0].wxh}}</el-button></p>
	  </el-dialog>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {HomeFilled,Iphone,ChatDotRound,Location} from '@element-plus/icons-vue'
	import {onMounted,ref,reactive} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const dialogTableVisible = ref(false)
	const wxqqllist = ref([])
	const sj = ref([])
	const sj1 = ref([])
	onMounted(async()=>{
		// 微信QQ数据
		var res1 = await axios.get("/webapi/wxqq/list")
		 sj.value = res1.data.data[0].landline
		wxqqllist.value = res1.data.data;
	})
	// 手机拨号
	const callPhone = ()=>{
		// const sj1 = sj.value
		window.location.href = 'tel:13522928738'
	}
	
</script>

<style lang="scss" scoped>
	/* 脚注模块 */
	.footer {
		height: 70px;
		background-color: #2e3033;
		margin: 0px auto;
		width: 1450px;
		margin-bottom: -2px;
	}

	.footer p {
		text-align: center;
		color: #868686;
		padding-bottom: 15px;
		font-size: 10px;
	}
	
</style>