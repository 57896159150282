<template>
	<el-row>
		<div class="footer hidden-sm-and-up">
			<br>
			<p v-html="footerlist.content"></p>
		</div>
	</el-row>
	<!-- 电脑模式 -->
	<div class="footer1 hidden-xs-only">
		<br>
		<p class="pii" v-html="footerlist.content"></p>
	</div>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	const footerlist = ref([])
	onMounted(async () => {
		var res = await axios.get("/webapi/wzsz/list")
		footerlist.value = res.data.data[0]
	})
</script>

<style lang="scss" scoped>
	/* 脚注模块 */
	.footer {
		// height: 100px;
		background-color: #282d34;
		margin: 0px auto;
		width:100%;
		min-height: 60px;
	}

	.footer1 {
		background-color: #282d34;
		margin: 0px auto;
		width: 1450px;
		min-height: 60px;
		margin-bottom: 0px;
	}

	a {
		background-color: aqua;
	}

	.pii {
		text-align: center;
		color: #868686;
		font-size: 16px;
	}

	.footer p {
		text-align: center;
		color: #868686;
		padding-bottom: 15px;
		font-size: 10px;
	}

	.footer1 p {
		text-align: center;
		color: #868686;
	}
</style>