<template>
	<el-col class="hidden-xs-only">
		<div class="lbt">
			<el-carousel height="520px">
				<el-carousel-item v-for="itme in gggllist" :key="_id">
					<img :src="proxy.$key + itme.avatar" />
				</el-carousel-item>
			</el-carousel>
		</div>
	</el-col>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const {proxy} = getCurrentInstance();
	const gggllist = ref([])
	onMounted(async () => {
		// 首页轮播图
		var res1 = await axios.get("/webapi/gggl/list")
		gggllist.value = res1.data.data
	})
</script>

<style lang="scss" scoped>
	/* 轮播图模块 */
	.lbt {
		width: 1450px;
		margin: 10px auto;
		// overflow: hidden;
	}

	img {
		width: 1450px;
		height: 520px;
	}

	.el-carousel__item {
		height: 600px;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}
</style>